import React, { useState, useEffect } from "react"
import { Container, Row, Col, NavItem, Modal } from "react-bootstrap"
import { Link } from "gatsby"
//import ScrollAnimation from "react-animate-on-scroll"
import Slider from "react-slick"
import axios from "axios"
import "./similarProperties.scss"
import SimilarPropertyImg from "../../../images/similar-property-img.png"
import propertyLabel from "../../../images/property-label.png"
import NoImage from "../../../images/no-image.png"
import PlayVideo from "@Components/PlayVideo/PlayVideo"
import getVideoId from "get-video-id"
import { ShowProcessedImage } from "@Components/common/ggfx-client/module/components/show-image"
import imageConfig from "../../../../static/images/config.json"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import Lightbox from "react-image-lightbox"
import {
  UserObjectStoreProvider,
  SaveItem,
  getUserobjectData,
  isAuthenticated,
  savedSearchParams,
} from "@starberry/myaccount-website-utils"
import Content from "../../Content/Content"
const SimilarProperties = props => {
  const [isPlay, setIsPlay] = useState(false)
  const [userObjects, setUserObjects] = useState({})
  const [videoid, setVideoId] = useState("")
  const [video_type, setVideoType] = useState("")

  const playVideoHandler = (video_url, video_type) => {
    setIsPlay(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState(true)

  function handleShow(video_url, video_type) {
    setShow(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }
  // Property images lightbox

  // Property details images lightbox
  const propertyImages = props?.propertyData?.images
  var propertyLightImages =
    propertyImages && propertyImages.map(img => img.srcUrl)
  // Property details images lightbox
  //
  const [propItems, setPropItems] = useState([])

  const getitems = async url => {
    try {
      const { data } = await axios.get(url,
        {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          },
        }
        )
      //
      //  console.log("PropertySimilar", data)
      setPropItems(data)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    //
    let url =
      process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/Property-details?pid=" +
      props?.propertyData?.id

    getitems(url)

    if (isAuthenticated()) {
      console.log("Success1")
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData()
          setUserObjects(userObjects.data.data)
          console.log("success")
        } catch (e) {
          console.log("Nope")
        }
      }
      getUserObjects()
    }
  }, [props?.propertyData?.id])
  //

  // Slider settings
  var settings = {
    dots: false,
    nav: true,
    infinite: propItems.length > 3 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          infinite: propItems.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: propItems.length > 1 ? true : false,
        },
      },
    ],
  }
  // Slider settings

  return (
    propItems.length > 0 && (
      <React.Fragment>
        <UserObjectStoreProvider>
          {/* <ScrollAnimation animateIn="fadeInUp"> */}
            <div className="relatednews-block opinion similar-properties">
              <Container>
                <Row>
                  <Col lg={12}>
                    <h2>Similar Properties that may Interest you...</h2>
                  </Col>
                </Row>

                <Slider className="opinion-list grid overright" {...settings}>
                  {propItems.map((hit, index) => {
                    // Property detail url structure
                    let uriStr = ""

                    if (
                      hit.search_type === "sales" &&
                      hit.department === "residential"
                    ) {
                      uriStr = `property-for-sale/`
                    } else if (
                      hit.search_type === "lettings" &&
                      hit.department === "residential"
                    ) {
                      uriStr = `property-to-rent/`
                    } else if (
                      hit.search_type === "sales" &&
                      hit.department === "commercial"
                    ) {
                      uriStr = `commercial-property-for-sale/`
                    } else if (
                      hit.search_type === "lettings" &&
                      hit.department === "commercial"
                    ) {
                      uriStr = `commercial-property-to-rent/`
                    }
                    // Property detail url structure
                    let videotourid =
                      hit?.video_tour?.url && getVideoId(hit?.video_tour?.url)

                    let virtualid =
                      hit?.virtual_url?.url && getVideoId(hit?.virtual_url?.url)
                    // ggfx images
                    let processedImages = JSON.stringify({})
                    if (hit?.imagetransforms?.images_Transforms) {
                      processedImages = hit.imagetransforms.images_Transforms
                    }
                    return (
                      <div className="property-card">
                        <div className="property-card-wrapper">
                          <div className="property-img">
                            {
                              <Link to={`/${uriStr}${hit.slug}-${hit.id}/`} onClick={() => window.scrollTo(0, 0)}>
                                {/* {JSON.stringify(hit?.images[0])} */}
                                {/* <ShowProcessedImage images={hit.images[0]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults.sizes} /> */}
                                {hit?.images && hit?.images[0]?.url ? (
                                  <ImageTransform
                                    imagesources={hit?.images[0]?.url}
                                    key={hit?.images[0]?.url}
                                    renderer="srcSet"
                                    imagename="property.images.detail"
                                    attr={{
                                      alt:
                                        hit?.display_address +
                                        " - DNG Estate Agents",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={hit.id}
                                    testparam={true}
                                  />
                                ) : (
                                  <img src={NoImage} alt="property" />
                                )}
                              </Link>
                            }
                            <div className="property-wrapper">
                              <div className="left-block">
                                {hit?.images && hit?.images?.length > 0 && (
                                  <div
                                    className="image-wrap"
                                    onClick={e => openPropertyImage(e, 0)}
                                  >
                                    <i className="icon-camera"></i>
                                    <div className="image-count">
                                      1/{hit?.images?.length}
                                    </div>
                                  </div>
                                )}
                                {/* Banner image popup */}
                                {isOpen && (
                                  <Lightbox
                                    mainSrc={propertyLightImages[photoIndex]}
                                    nextSrc={
                                      propertyLightImages[
                                        (photoIndex + 1) %
                                          propertyLightImages.length
                                      ]
                                    }
                                    prevSrc={
                                      propertyLightImages[
                                        (photoIndex +
                                          propertyLightImages.length -
                                          1) %
                                          propertyLightImages.length
                                      ]
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          propertyLightImages.length -
                                          1) %
                                          propertyLightImages.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          propertyLightImages.length
                                      )
                                    }
                                  />
                                )}
                                {/* Banner image popup */}
                                <div className="property-view">
                                  {hit.video_tour?.url &&
                                    (videotourid?.service === "youtube" ? (
                                      <div className="video-wrap">
                                        <button
                                          onClick={() =>
                                            playVideoHandler(
                                              hit.video_tour?.url,
                                              "youtube"
                                            )
                                          }
                                        >
                                          <i className="icon-play"></i>
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="video-wrap">
                                        <button
                                          onClick={() =>
                                            handleShow(
                                              hit.video_tour?.url,
                                              "custom_video"
                                            )
                                          }
                                        >
                                          <i className="icon-play"></i>
                                        </button>
                                      </div>
                                    ))}
                                  {hit.virtual_url?.url &&
                                    (virtualid?.service === "youtube" ? (
                                      <div className="video-wrap">
                                        <button
                                          onClick={() =>
                                            playVideoHandler(
                                              hit.virtual_url?.url,
                                              "youtube"
                                            )
                                          }
                                        >
                                          <i className="icon-play"></i>
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="video-wrap">
                                        <button
                                          onClick={() =>
                                            handleShow(
                                              hit.virtual_url?.url,
                                              "custom_video"
                                            )
                                          }
                                        >
                                          <i className="icon-play"></i>
                                        </button>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              <SaveItem
                                type="property"
                                pid={hit.id}
                                userObjects={userObjects}
                              >
                                <div className="right-block">
                                  <button type="button">
                                    <i className="icon-heart" />
                                    <span className="btn-text d-none d-md-inline-block"></span>
                                  </button>
                                  
                                </div>
                              </SaveItem>
                            </div>
                            {hit.status && hit.status!=="For Sale"&&(
                              <div className="property-tag">{hit.status}</div>
                            )}
                          </div>
                          <div className="property-details">
                            <div className="property-name">
                              <Link to={`/${uriStr}${hit.slug}-${hit.id}/`} onClick={() => window.scrollTo(0, 0)}>
                                {hit.display_address && hit.display_address}
                                {/* <br />{hit.title && hit.title} */}
                              </Link>
                            </div>
                            <p className="property-desc-content">
                            {hit.description &&                        
                         <Content
                         Content={hit.description}
                         page="search-result"
                         uriStr={uriStr}
                         hit={hit}
                         />
      }
                            </p>
                            <div className="property-price">
                              {hit?.price_qualifier === "POA"
                                ? "€ Price on Application"
                                : hit?.price > 10000 && hit?.search_type !== "lettings"
                                ? `€${parseFloat(hit?.price).toLocaleString()}`
                                : hit?.search_type === "lettings" && hit?.price > 100
                                ? `€${parseFloat(hit?.price).toLocaleString()} ${
                                  hit?.price_qualifier
                                  ? hit?.price_qualifier
                                  : ""}`
                                : "€ Price on Application"
                              }
                            </div>
                            <div className="bottom-block">
                              {hit.bedroom >= 1 && (
                                <div className="bedroom-count">
                                  <i className="icon-bed"></i>
                                  {hit.bedroom}
                                </div>
                              )}
                              {hit.bathroom >= 1 && (
                                <div className="bathroom-count">
                                  <i className="icon-tub"></i>
                                  {hit.bathroom}
                                </div>
                              )}
                              {hit.reception >= 1 && (
                                <div className="lounge-count d-none d-xl-flex">
                                  <i className="icon-couch"></i>
                                  {hit.reception}
                                </div>
                              )}
                              {hit.floorarea_min >= 1 &&(
                                <div className="property-area">
                                  <i className="icon-cube"></i>
                                  {/* {`${hit.floorarea_min}m`} */}
                                  {(hit.floorarea_min / 10.7639)
                                    .toFixed(1)
                                    .replace(".0", "")}{" "}
                                  {"m"} 
                                  <sup>2</sup>
                                </div>
                              )}
                              {hit.extras?.extrasField &&
                                hit.extras?.extrasField.pBERRating && (
                                  <div className="property-label">
                                    <div className="d-flex justify-content-start align-items-center property-ber-rating">
                                      <div className="ber-black">BER</div>
                                      <div
                                        className={
                                          "ber-green " +
                                          hit.extras.extrasField.pBERRating.toLowerCase()
                                        }
                                      >
                                        {hit.extras.extrasField.pBERRating}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  {/* <div className='property-card'>
                                <div className="property-card-wrapper">
                                    <div className="property-img">
                                        <Link to={`/`}>
                                          <img src={SimilarPropertyImg} alt="property" />
                                          <div className="property-wrapper">
                                              <div className="left-block">
                                                  <div className="image-wrap">
                                                      <i className="icon-camera"></i>
                                                        <div className="image-count">1/{"2"}</div>
                                                  </div>
                                                  <div className="property-view">
                                                      <div className="video-wrap">
                                                          <button>
                                                              <i className="icon-play"></i>
                                                          </button>
                                                      </div>
                                                      <div className="floor-plan">
                                                          <button>
                                                              <i className="icon-floor-plan"></i>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="right-block">
                                                  <button>
                                                      <i className={'icon-heart' } />
                                                      <span className="span-btn-text d-none d-md-inline-block">{'save' }</span>
                                                  </button>
                                              </div>
                                          </div>
                                          <div className="property-tag">{"For sale"}</div>
                                        </Link>
                                    </div>
                                    <div className="property-details">
                                        <div className="property-name">
                                            <Link to={`/`}>
                                                {"test address"}
                                            </Link>
                                        </div>
                                        <p>{"test title"}</p>
                                        <div className="property-price">€{"124562"}</div>
                                        <div className="bottom-block">
                                            <div className="bedroom-count">
                                                <i className="icon-bed"></i>{"2"}
                                            </div>
                                            <div className="bathroom-count">
                                                <i className="icon-tub"></i>{"1"}
                                            </div>
                                            <div className="lounge-count d-none d-xl-flex">
                                                <i className="icon-couch"></i>{"1"}
                                            </div>
                                            <div className="property-area">
                                                <i className="icon-cube"></i>{`${'132'}m`}<sup>2</sup>
                                            </div>
                                            <div className="property-label">
                                                <img src={propertyLabel} alt="label" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                </Slider>
              </Container>
            </div>
            {video_type === "youtube" ? (
              <PlayVideo
                isOpen={isPlay}
                videoId={videoid}
                stopPlay={() => {
                  setIsPlay(false)
                  setVideoType("")
                }}
              />
            ) : (
              <Modal
                show={show}
                fullscreen={fullscreen}
                onHide={() => {
                  setShow(false)
                  setVideoType("")
                }}
                dialogClassName="modal-fullscreen property-details-modal"
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="p-0">
                  <iframe src={videoid}></iframe>
                </Modal.Body>
              </Modal>
            )}
          {/* </ScrollAnimation> */}
        </UserObjectStoreProvider>
      </React.Fragment>
    )
  )
}

export default SimilarProperties
