import React, { useState, useEffect } from "react"
// import { useQuery } from "@apollo/client"
import { Container, Row, Col } from "react-bootstrap"
// import gql from "graphql-tag"
// import { graphql } from "gatsby"
// import $ from "jquery"
// import { Link } from "gatsby"
import Layout from "@Components/Layout/layout"
import Seo from "@Components/SEO/seo"
import PropertySection from "@Components/PropertyDetails/PropertySection/PropertySection"
import PropertyValuation from "@Components/SearchResults/Valuation/PropertyValuation"
import MapComponent from "@Components/PropertyDetails/PropertyMap/Map"
import SimilarProperties from "@Components/PropertyDetails/SimilarProperties/SimilarProperties"
// import BrandLogo from "../images/logo.svg"
import { UserObjectStoreProvider } from "@starberry/myaccount-website-utils"
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
// import NotFoundPage from "../pages/404"
import AddTrendingPropertyComponent from "@Components/PropertyDetails/TrendingProperties/addTrendingProperties"
import { toTitleCase } from "@Components/property-search/utils"
import PopularSearch from "../Components/StaticPageTwo/PopularSearches/PopularSearch"

const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"

// const PROPERTY_DETAILS = gql`
//   query GetProperty($id: String!) {
//     properties(where: { id: $id, publish:true }) {
//       id
//       crm_id
//       crm_provider
//       status
//       department
//       search_type
//       address
//       price
//       building
//       price_qualifier
//       bedroom
//       bathroom
//       reception
//       crm_negotiator_id
//       office_crm_id
//       office_crm_id1
//       display_address
//       title
//       description
//       slug
//       images
//       imagetransforms
//       publish
//       latitude
//       longitude
//       room_details
//       accomadation_summary
//       virtual_tour
//       video_tour
//       floorplan
//       floorarea_min
//       floorarea_max
//       floorarea_type
//       brochure
//       extras
//       epc
//       special
//     }
//   }
// `

const PropertyDetailsTemplate = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const data = props.pageContext.propertyData
  const handleShow = event => {
    setShow(true)
  }

  var seo_image_url = ""

if(typeof window !== "undefined"&&window){
  var s = document?.getElementById("chat-widget-container")
  var t = document?.getElementById("livechat-eye-catcher")
  
  if(s){
    s.style.display = 'none'
  }
  if(t){
    t.style.display = 'none'
  }
}

    if (typeof window !== "undefined"&&window.adfenix) {
      window.adfenix.track('bookshowing');
      
  }

  const ReactComment = ({ text }) => {
    return <div dangerouslySetInnerHTML={{ __html: `<!--${text}-->` }}/>
}

var property_type = ""

property_type = data?.building
  ? typeof data?.building === "object"
    ? data?.building.toString()
    : data?.building
  : ""

var price = "€" + data?.price?.toLocaleString()

if (data?.price_qualifier === "POA") {
  price = data?.price_qualifier
} else {
  if (data?.search_type === "lettings" && data?.price_qualifier) {
    price =
      "€" +
      data?.price?.toLocaleString() +
      " " +
      data?.price_qualifier
  }
}

var flr_type = "Sq. M.",
  size_str = ""

// if(data?.floorarea_type === "squareFeet"){
//     flr_type = "Sq. ft.";
// }
// if(data?.floorarea_type === "squareMeter"){
//     flr_type = "Sq. M.";
// }
if (data?.floorarea_min && data?.floorarea_max) {
  var floor_min = (data?.floorarea_min / 10.7639)
    .toFixed(1)
    .replace(".0", "")
  var floor_max = (data?.floorarea_max / 10.7639)
    .toFixed(1)
    .replace(".0", "")
  size_str = "from " + floor_min + " to " + floor_max + " " + flr_type
} else if (floor_min) {
  var floor_min = (data?.floorarea_min / 10.7639)
    .toFixed(1)
    .replace(".0", "")
  size_str = "above " + floor_min + " " + flr_type
}

var bedroom_str =
  data?.bedroom > 0
    ? parseInt(data?.bedroom) === 1
      ? `with ${data?.bedroom} bedroom`
      : `with ${data?.bedroom} bedrooms`
    : ""

var meta_title = `${data?.status} in ${data?.display_address} ${size_str}`

if (property_type) {
  meta_title = `${toTitleCase(
    property_type
  )} ${data?.status.toLowerCase()} in ${
    data?.display_address
  } ${size_str}`
}

if (data?.department === "residential") {
  meta_title = `${data?.status} ${bedroom_str} in ${data?.display_address} at ${price}`

  if (property_type) {
    meta_title = `${toTitleCase(
      property_type
    )} ${data?.status.toLowerCase()} ${bedroom_str} in ${
      data?.display_address
    } at ${price}`
  }
}

var meta_description = `Know the details of ${property_type} ${data?.status.toLowerCase()} in ${
  data?.display_address
} ${size_str} with DNG. Book a viewing to get assistance in finding the right ${property_type} for you.`

if (data?.department === "residential") {
  meta_description = `Know the details of ${property_type} ${data?.status.toLowerCase()} ${bedroom_str} in ${
    data?.display_address
  } at ${price}. Book a viewing to get assistance in finding the right ${property_type} for you.`
}

var get_area = data?.address?.county
  ? data?.address?.county
  : data?.address?.town
  ? data?.address?.town
  : data?.address?.address3
  ? data?.address?.address3
  : data?.address?.address2

var searchParams = {
  areas: get_area?.replace("Co. ", "").toLowerCase(),
}

let processedImages = JSON.stringify({});
const adfImages=data?.images && data?.images?.length > 0 && data?.images[0]?.url

if (data?.imagetransforms?.images_Transforms) {
  processedImages = data?.imagetransforms.images_Transforms;
  var resized_images = JSON.parse(processedImages);
  const firstImg = data.images && data.images.length > 0 && data.images[0].url;
  // const SrcCftle = data.images && data.images.length > 0 && data.images[0] != null && data.images[0].url.substring(data.images && data.images.length > 0 && data.images[0].url.indexOf(STAGE_ENV));
  // const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.webp ? resized_images[SrcCftle]?.webp['440x320'] : data.images && data.images.length > 0 && data.images[0].url;
  seo_image_url =  firstImg;
}

  return (<> 
    <Layout>      
          <React.Fragment>
           <ReactComment text={`property-bathrooms:"${data?.bathroom}"`}/>
           <ReactComment text={`property-bedrooms:"${data?.bedroom}"`}/>
           <ReactComment text={`property-home-type:"${data?.building[0]}"`}/>
           <ReactComment text={`property-images:"${adfImages}"`}/>
           <ReactComment text={`property-price:"${data?.price}"`}/>
           <ReactComment text={`property-address:"${data?.display_address}"`}/>
           <ReactComment text={`property-latitude:"${data?.latitude}"`}/>
           <ReactComment text={`property-longitude:"${data?.longitude}"`}/>
           <ReactComment text={`property-postcode:"${data?.address?.postcode}"`}/>
           <ReactComment text={`property-status:"${data?.status}"`}/>
           <ReactComment text={`property-message:"${data?.title}"`}/>
           <ReactComment text={`property-area-large:"${data?.address?.country}"`}/>
           <ReactComment text={`property-area-small:"${data?.address?.address2}"`}/>
           <ReactComment text={`property-listing-type:"${data?.department}"`}/>

              <Seo
                title={meta_title}
                description={meta_description}
                image={seo_image_url}
              />

              <PropertySection propertyData={data} />
              {/* {JSON.stringify(data)} */}
              {data?.latitude || data?.longitude ? (
                <div
                  className="property-details-landing-map mt-4"
                  id="property-details-map"
                  data-item="property-details-map"
                >
                  <MapComponent property_details_map_data={data} />
                </div>
              ) : null}
              <PropertyValuation />
              <SimilarProperties propertyData={data} />
              <AddTrendingPropertyComponent
                socket={false}
                baseUrl={process.env.GATSBY_STRAPI_FORM_URL || null}
                accessToken={process.env.GATSBY_STRAPI_FORM_TOKEN}
                scrollHeight={300}
              />

              {/* <Modal
                                show={show}
                                centered={true}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                                dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup">
                                <Modal.Header closeButton className="contact-close-btn">
                                    <Modal.Title className="w-100">Email to {Page.Name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="form">
                                            <TeamForm toemail={Page.Email} />
                                </Modal.Body>
                            </Modal> */}

              <section className="popular-search-result">
                <Container>
                  <Row>
                    <Col>
                      <PopularSearch
                        searchParams={searchParams}
                        department={data?.department}
                        SearchType={"Property_Details"}
                        status={data?.status}
                        areaName={searchParams.areas}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            </React.Fragment>
        {/* <Router>
          <Switch>
            <Route component={NotFoundPage} />
          </Switch>
        </Router> */}
      
    </Layout>
    </>
  )
}
// export const query = graphql`
// query ($id: ID!) {
//   glstrapi {
//   property(id: $id) {
//       id
//       crm_id
//       crm_provider
//       status
//       department
//       search_type
//       address
//       price
//       building
//       price_qualifier
//       bedroom
//       bathroom
//       reception
//       crm_negotiator_id
//       office_crm_id
//       office_crm_id1
//       display_address
//       title
//       description
//       slug
//       images
//       imagetransforms
//       publish
//       latitude
//       longitude
//       room_details
//       accomadation_summary
//       virtual_tour
//       video_tour
//       floorplan
//       floorarea_min
//       floorarea_max
//       floorarea_type
//       brochure
//       extras
//       epc
//       special
//     }
//   }
// }
// `

// export default PropertyDetailsTemplate

const PropertyDetailsTemplateComp = props => {
  return (
    <UserObjectStoreProvider>
      <PropertyDetailsTemplate {...props} />
      </UserObjectStoreProvider>
  )
}

export default PropertyDetailsTemplateComp
