import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './valuation.scss';
import { Valuation_Page_Url } from "@Components/common/site/constants"
import { Link } from "gatsby"
const PropertyValuation = () => {
	return (
		<section className="valuation property-valuation w-100 mb-0">
			<Container>
				<Row>
					<Col>
						<div className="valuation-banner">
							<div className="valuation-details">
								<i className="icon-home"></i>
								<h3>start <span className="highlight">marketing your property</span> with dng</h3>
								<p>Book your property valuation today with one of our experts.</p>
							</div>
							<div className="valuation-cta">
							<Link to={Valuation_Page_Url.alias+"/"} className="btn">book valuation</Link>
								{/* <button type='button' className="btn">book valuation</button> */}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default PropertyValuation;
