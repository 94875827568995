import React, { useState, useEffect } from "react"
import axios from "axios"
import { useLocation } from "@reach/router";
import io from 'socket.io-client';
const ipUrl  = 'https://api.ipify.org/?format=json'

function AddTrendingPropertyComponent({socket=false, baseUrl=null, accessToken=null, scrollHeight=0, afterSecond=0}) {
    
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken}`
    }

    const location = useLocation();     
    const [ip, setIP] = useState(null);

    //creating function to load ip address from the API 
    const getData = async () => {
        try{
            const res = await axios.get(ipUrl);
            setIP(res.data?.ip)
        }catch(error){
          console.log(JSON.stringify(error))
        }
    }

    const postData = async (params) => {
        try{
           await axios.post(`${baseUrl}/stb-trending-properties/addProperty`, params, {headers});
        }catch(error){
          console.log(JSON.stringify(error))
        }
    }
    
    useEffect( () => {
        getData()
    }, [])

    if(baseUrl === null){
        return null
    }   
    
    let webSocket = null
    if(socket)
    webSocket = io(baseUrl);    

    const propertyId = location.pathname.substring(location.pathname.lastIndexOf('-') + 1).replace(/\//g, '')
 
    if(propertyId === 'undefined'){
        return null
    }

    setTimeout(()=>{
        if(ip){
            if(socket){
                webSocket.emit('viewed', {ip, "id":propertyId}); 
            }else{
                postData({ip, "id":propertyId, "agent":navigator.userAgent});
            }
        }
    }, afterSecond);

    if(scrollHeight && ip){
        var ticking = false;
        window.addEventListener('scroll', ()=>{
            if(!ticking && window.scrollY >= scrollHeight){                
                ticking = true;
                if(socket){
                    webSocket.emit('scrolled', {ip, "id":propertyId, scrollY:window.scrollY})
                }else{
                    postData({ip, "id":propertyId, "agent":navigator.userAgent, "scrolled":true});
                }
            }
        }, true);
    }
    return null  
};

export default AddTrendingPropertyComponent;

