import React, { useEffect, Component } from "react"
import { GoogleMap, useJsApiLoader, LoadScript, InfoWindow, Marker} from "@react-google-maps/api"

import marker_icon from "../../../../static/images/marker.png";
import googleMapStyles from "./mapStyle";
const PropertyDetailMap = (props) => {
  const containerStyle = {
    width: "100%",
    height: props.height,
  }

  const center = {
    lat: props.lat,
    lng: props.lng,
  }

  const position = {
    lat: props.lat,
    lng: props.lng,
  }

  const [map, setMap] = React.useState(null);
  const [streetView, setStreetView] = React.useState(props.streetView);
  const [showingInfoWindow, setShowingInfoWindow] = React.useState(false);
  const [activeMarker, setActiveMarker] = React.useState({});
  const [selectedPlace, setSelectedPlace] = React.useState({});
  const [currentInfo, setCurrentInfo] = React.useState('');


  useEffect(() => {
    setStreetView(props.streetView);
  },[props.streetView]);


  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const onMarkerClick = (infoId, e) => {
    //setShowingInfoWindow(true);
    if(infoId === currentInfo){
      setCurrentInfo('')
    } else{
      setCurrentInfo(infoId)
    }
    
  }
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_MAPS_API_KEY
  })

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={props.zoom ? props.zoom : 14}
        onUnmount={onUnmount}
        styles={googleMapStyles}
        options={{
          mapTypeControl: false,
          streetViewControl:false
        }}
      >
        <Marker position={position} icon={marker_icon} onClick={(e) => onMarkerClick('info-1', e)}>
        </Marker>

      </GoogleMap>
    
  ) : <></>
}

export default React.memo(PropertyDetailMap)